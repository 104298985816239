import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import {
  subscribeNotifications,
  pushNotification,
} from '../services/firebase.service';

export default class extends Controller {
  static targets = [
    'enabledIcon',
    'disabledIcon',
    'count',
    'notifications',
    'linkUrl',
  ];

  connect() {
    this.updateIconVisibility(Notification.permission === 'granted');
  }

  updateIconVisibility(isEnabled) {
    this.enabledIconTarget.classList.toggle('d-none', isEnabled);
    this.disabledIconTarget.classList.toggle('d-none', !isEnabled);
    this.countTarget.classList.toggle('d-none', !isEnabled);
  }

  async openNotifications() {
    if (
      this.linkUrlTarget.dataset.url &&
      Notification.permission === 'granted'
    ) {
      window.location.href = this.linkUrlTarget.dataset.url;
    }

    await this.subscribeToNotificationService();
  }

  async subscribeToNotificationService() {
    try {
      const { eventBus, fcmToken } = await subscribeNotifications();

      this.updateIconVisibility(true);
      await this.updateFcmToken(fcmToken);
      this.setupNotificationListener(eventBus);
    } catch (error) {
      console.error('Error subscribing to notification service', error);
    }
  }

  async updateFcmToken(fcmToken) {
    await fetch('/api/v1/chat/notifications/update_fcm_token', {
      method: 'PATCH',
      body: JSON.stringify({ fcm_token: fcmToken }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
      credentials: 'same-origin',
    });
  }

  setupNotificationListener(eventBus) {
    eventBus.on('notification', (payload) => {
      this.handleNotification(payload);
    });
  }

  handleNotification(payload) {
    pushNotification(payload);
  }
}
