import { getI18n } from 'react-i18next';
import {
  endOfDay,
  endOfMonth,
  format,
  lastDayOfMonth,
  startOfMonth,
  sub,
} from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { RentsListSortType } from '@/lib/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { RentStatus } from '@/features/admin/rents/types';

const i18n = getI18n();

type ListRentStatusOption = {
  status: RentStatus;
  displayValue: string;
};

const listRentStatus: Array<ListRentStatusOption> = [
  {
    status: 'due',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.due'
    ),
  },
  {
    status: 'in_flight',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.in_flight'
    ),
  },
  {
    status: 'partially_collected',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.partially_collected'
    ),
  },
  {
    status: 'collected',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.collected'
    ),
  },
  {
    status: 'overflow',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.overflow'
    ),
  },
  {
    status: 'too_late',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.too_late'
    ),
  },
  {
    status: 'failed',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.failed'
    ),
  },
  {
    status: 'pending_payout',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.pending_payout'
    ),
  },
  {
    status: 'resolved',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.status_options.resolved'
    ),
  },
];

type ListRentReminderOption = {
  reminders_sent: number;
  displayValue: string;
};

const listRentReminders: Array<ListRentReminderOption> = [
  {
    reminders_sent: 0,
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.reminder_options.0'
    ),
  },
  {
    reminders_sent: 1,
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.reminder_options.1'
    ),
  },
  {
    reminders_sent: 2,
    displayValue: i18n.t(
      'js.features.admin.rents.rent_list.reminder_options.2'
    ),
  },
];

const useRentListFilters = () => {
  const [selectedListRentStatus, setSelectedListRentStatus] = useState<
    ListRentStatusOption | undefined
  >(undefined);
  const [selectedListRentReminder, setSelectedListRentReminder] =
    useState<ListRentReminderOption | null>(null);
  const [rentStatusQuery, setRentStatusQuery] = useState('');
  const [rentReminderQuery, setRentReminderQuery] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(
    sub(new Date(format(new Date(), 'yyyy-MM-01')), { months: 0 })
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    endOfDay(
      lastDayOfMonth(
        sub(new Date(format(new Date(), 'yyyy-MM-01')), { months: 0 })
      )
    )
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const { setColumnToSort, sortDirection, selectedColumnToSort } =
    useColocApi<RentsListSortType>()!;
  const [selectedHouse, setSelectedHouse] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [selectedContractId, setSelectedContractId] = useState<string | undefined>(undefined);
  const [housesQuery, setHousesQuery] = useState('');

  // Set default sorting to house name
  useEffect(() => {
    setColumnToSort('house_name');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for URL parameters
  // TODO: refactor this into a different hook.
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const tenantFullName = queryParams?.tenant_name;
    const houseId = queryParams?.house_id;
    const contractId = queryParams?.contract_id;
    const houseName = queryParams?.house_name;
    const startDateQuery = queryParams?.start_date;
    const endDateQuery = queryParams?.end_date;
    const status = queryParams?.status;

    if (houseId && houseName) {
      setSelectedHouse({ id: houseId, name: houseName });
    }

    if (status) {
      const statusOption = listRentStatus.find(rentStatus => rentStatus.status === status as RentStatus)

      setSelectedListRentStatus(statusOption)
    }

    if (contractId) {
      setSelectedContractId(contractId)
    }

    if (tenantFullName) {
      setSearchQuery(tenantFullName);
    }

    if (startDateQuery) {
      setStartDate(startOfMonth(new Date(startDateQuery)));
      if (!endDateQuery) {
        setEndDate(undefined);
      }
    } else if (contractId) {
      setStartDate(undefined)
    }

    if (endDateQuery) {
      setEndDate(endOfMonth(endOfDay(new Date(endDateQuery))));
    } else if (contractId) {
      setEndDate(undefined)
    }
  }, []);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredListRentsStatusArray = useMemo(() => {
    return listRentStatus.filter((statusObject) =>
      statusObject.displayValue.includes(rentStatusQuery)
    );
  }, [rentStatusQuery]);

  const filteredListRentsReminderArray = useMemo(
    () =>
      listRentReminders.filter((reminderObj) =>
        reminderObj.displayValue.includes(rentReminderQuery)
      ),
    [rentReminderQuery]
  );

  const setSelectedListRentStatusByValue = useCallback(
    (status: RentStatus) => {
      const statusOption = listRentStatus.find(
        (rentStatus) => rentStatus.status === status
      );

      setSelectedListRentStatus(statusOption);
    },
    []
  );
  return {
    selectedListRentStatus,
    setSelectedListRentStatus,
    setSelectedListRentStatusByValue,
    selectedListRentReminder,
    setSelectedListRentReminder,
    setRentStatusQuery,
    rentStatusQuery,
    rentReminderQuery,
    setRentReminderQuery,
    filteredListRentsStatusArray,
    filteredListRentsReminderArray,
    startDate,
    endDate,
    setDateRange,
    searchQuery,
    setSearchQuery,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
    setSelectedHouse,
    selectedContractId,
    setSelectedContractId,
    housesQuery,
    setHousesQuery,
  };
};

const GetRentsListFiltersContext = createContext<
  ReturnType<typeof useRentListFilters> | undefined
>(undefined);

const GetRentsListFiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useRentListFilters();

  return (
    <GetRentsListFiltersContext.Provider value={hook}>
      {children}
    </GetRentsListFiltersContext.Provider>
  );
};

const useRentsListFiltersContext = () => {
  const context = useContext(GetRentsListFiltersContext);

  if (context === null) {
    throw new Error(
      'useRentsListFiltersContext must be used within a GetRentsListFiltersContextProvider'
    );
  }

  return context;
};

export { GetRentsListFiltersContextProvider, useRentsListFiltersContext };